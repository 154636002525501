<template>
	<div>
		<Breadcrumb titulo="CHATBOT - LAYOUT" :items="item"  />

		<b-container fluid>
			<b-row cols="2" class="m-4">

				<b-col cols="6">

					<b-card no-body class="container-config">
						<b-tabs card lazy>

							<b-tab title="LAYOUT" title-link-class="text-secondary" active>

								<b-row align-v="center" class="container-config-arquivo mx-0 mb-3">
									<b-form-group class="input-file m-0">
										<b-form-file ref="file-input"
													 class="text-center"
													 placeholder="Alterar o ícone do chat"
													 accept="image/*"
													 v-model="imagemBotSelector">
										</b-form-file>
									</b-form-group>
								</b-row>

								<b-row cols="2" class="container-config-inputs mx-0">
									<b-col class="pr-3">
										<input-padrao label="Nome do Bot" v-model="nomeBot" />
									</b-col>

									<b-col class="pl-3">
										<input-padrao label="Texto do Balão do Chat" v-model="mensagemBalao" />
									</b-col>

									<b-col class="pr-3">
										<input-color label="Cor Principal"
													 :cor="corPrincipal"
													 @change="corPrincipal = $event" />
									</b-col>


									<b-col class="pl-3">
										<input-color label="Cor Secundaria"
													 :cor="corSecundaria"
													 @change="corSecundaria = $event" />
									</b-col>

									<b-col class="pr-3">
										<input-padrao label="Texto do Editor" v-model="placeholderChat" />
									</b-col>

									<b-col class="pl-3">
										<input-padrao label="Texto da Seleção de Opção" v-model="placeholderOpcao" />
									</b-col>
								</b-row>

								<div class="w-100 mt-4 text-right">
									<botao-padrao texto="Salvar" @click="salvarChatConfig" />
								</div>

							</b-tab>

							<b-tab title="INCORPORAR NO SITE" title-link-class="text-secondary" :title-item-class="chatScript != null ? '' : 'd-none'">
								<div class="w-100 my-2 text-right">
									<botao-padrao texto="Copiar" @click="copiarTextoScript" />
								</div>

								<textarea class="campo-texto-link" readonly id="texto-script-chat" v-model="chatScript"></textarea>
							</b-tab>

						</b-tabs>
					</b-card>

				</b-col>

				<b-col cols="6">
					<iframe id="chatbot-container-live-preview"></iframe>
				</b-col>

			</b-row>
		</b-container>

	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import axios from 'axios'

	import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'
	import InputFile from '@/components/inputs/InputFile.vue'
	import InputColor from '@/components/inputs/InputColor.vue'
	import InputPadrao from '@/components/inputs/InputPadrao.vue'
	import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'

	export default {
		name: 'ChatConfiguracaoPage',
		components: {
			BotaoPadrao,
			Breadcrumb,
			InputFile,
			InputColor,
			InputPadrao
		},
		data: function () {
			return {
				item: [
                    {
                        id: "1",
                        classe: "fas fa-cogs",
                        texto: "Cadastros Básicos",
                        link: "/cadastros-basicos",
                    },

					{
						id: '1',
                        classe: 'fa fa-robot',
						texto: 'Chatbot-Layout',
						link: '/chat-configuracao'
					}
				],

				nomeBot: '',
				mensagemBalao: '',
				placeholderChat: '',
				placeholderOpcao: '',
				corPrincipal: '',
				corSecundaria: '',
				imagemBot: '',
				imagemBotSelector: null,
				chatScript: null,
				container: null
			}
		},

		computed: {
			...mapGetters({
				chatConfig: 'chatConfiguracao/getChatConfiguracao',
			})
		},

		watch: {
			chatConfig: {
				immediate: true,
				handler() {
					if (this.chatConfig) {
						this.nomeBot = this.chatConfig.NomeBot;
						this.mensagemBalao = this.chatConfig.MensagemBalaoChat;
						this.placeholderChat = this.chatConfig.PlaceholderChat;
						this.placeholderOpcao = this.chatConfig.PlaceholderOpcaoChat;
						this.corPrincipal = this.chatConfig.CorPrincipal;
						this.corSecundaria = this.chatConfig.CorSecundaria;
						this.imagemBot = this.chatConfig.ImagemBot;
					}
				}
			},
			nomeBot(value) {
				this.changePreviewChatBotConfig("name", value);
			},
			mensagemBalao(value) {
				this.changePreviewChatBotConfig("tooltip", value);
			},
			corPrincipal(value) {
				this.changePreviewChatBotConfig("main-color", value);
			},
			corSecundaria(value) {
				this.changePreviewChatBotConfig("sub-color", value);
			},
			placeholderChat(value) {
				this.changePreviewChatBotConfig("placeholder", value);
			},
			placeholderOpcao(value) {
				this.changePreviewChatBotConfig("options-label", value);
			},
			imagemBot(value) {
				this.changePreviewChatBotConfig("image", `data:image/png;base64,${value}`);
			},
			imagemBotSelector(file) {
				if (file == null) {
					this.imagemBot = this.chatConfig.ImagemBot;
					return;
				}
				let reader = new FileReader();
				reader.onload = () => this.imagemBot = reader.result.split(",")[1];
				reader.readAsDataURL(file);
			}
		},

		methods: {
			...mapActions({
				buscaChatConfiguracao: 'chatConfiguracao/buscaChatConfiguracao',
				salvarChatConfiguracao: 'chatConfiguracao/salvarChatConfiguracao'
			}),
			changePreviewChatBotConfig(key, value) {
				if (this.container.ChatBot == null || this.container.ChatBot.config == null) return;
				this.container.ChatBot.config.chatbot[key] = value;
				switch (key) {
					default:
						break;
					case "name":
						this.container.$("#chatbot-name").html(value);
						this.container.ChatBot.config.chatbot.name = value;
						break;
					case "tooltip":
						this.container.$("#chatbot-tooltip").html(value);
						this.container.ChatBot.config.chatbot.tooltip = value;
						break;
					case "main-color":
						this.container.$(":root").css("--chatbot-color-a", value);
						this.container.ChatBot.config.chatbot["main-color"] = value;
						break;
					case "sub-color":
						this.container.$(":root").css("--chatbot-color-b", value);
						this.container.ChatBot.config.chatbot["sub-color"] = value;
						break;
					case "placeholder":
						this.container.$("#chatbot-input-field").attr("placeholder", value);
						this.container.ChatBot.config.chatbot.placeholder = value;
						break;
					case "options-label":
						this.container.$("#chatbot-question-options").attr("data-label", value);
						this.container.ChatBot.config.chatbot["options-label"] = value;
						break;
					case "image":
						this.container.$("#chatbot-image").css("background-image", `url(${value})`);

						this.container.ChatBot.config.chatbot.image = `url(${value})`;
						break;
				}
			},
			refreshPreviewChatBotConfig() {
				(config => {
					config.name = this.nomeBot;
					config.tooltip = this.mensagemBalao;
					config.image = `url(data:image/png;base64,${this.imagemBot})`;
					config.placeholder = this.placeholderChat;
					config["options-label"] = this.placeholderOpcao;
					config["main-color"] = this.corPrincipal;
					config["sub-color"] = this.corSecundaria;
				})(this.container.ChatBot.config.chatbot);
				($ => {
					$("#chatbot-name").html(this.nomeBot);
					$("#chatbot-tooltip").html(this.mensagemBalao);
					$(":root").css("--chatbot-color-a", this.corPrincipal);
					$(":root").css("--chatbot-color-b", this.corSecundaria);
					$("#chatbot-input-field").attr("placeholder", this.placeholderChat);
					$("#chatbot-question-options").attr("data-label", this.placeholderOpcao);
					$("#chatbot-image").css("background-image", `url(data:image/png;base64,${this.imagemBot})`);
				})(this.container.$)
			},
			copiarTextoScript() {
				let campoTexto = document.querySelector("#texto-script-chat");
				campoTexto.focus();
				campoTexto.select();
				document.execCommand('copy');
			},
			salvarChatConfig() {
				this.$refs['file-input'].reset();

				const config = {
					'Id': this.chatConfig.Id,
					'EmpresaId': this.chatConfig.EmpresaId,
					'ImagemBot': this.imagemBot,
					'NomeBot': this.nomeBot,
					'MensagemBalaoChat': this.mensagemBalao,
					'PlaceholderChat': this.placeholderChat,
					'PlaceholderOpcaoChat': this.placeholderOpcao,
					'CorPrincipal': this.corPrincipal,
					'CorSecundaria': this.corSecundaria,
				}
				this.salvarChatConfiguracao(config);

				this.$bvToast.toast('Configuração de layout salva com sucesso!', {
					toaster: 'b-toaster-bottom-center',
					variant: 'success',
					solid: true,
					autoHideDelay: 3000
				})
			},
			buscarChatScript() {
				axios.get('api/chat/script')
					.then(res => {
						this.chatScript = res.data;
					})
					.catch(err => { console.log(err); });
			},
			buscarChatHash() {
				axios.get('api/chat/chat-key').then(res => {
					$("#chatbot-container-live-preview").after(
						$("<script />").attr({
							id: "sd-chatbot",
							src: `${this.$hostOmni}/lib/chatbot/root.js`,
							"data-container": "#chatbot-container-live-preview",
							"data-hash": res.data,
							"data-js": "live-preview.js"
						})
					);
				}).catch(err => { console.log(err); });
			}
		},

		mounted() {
			this.buscarChatHash();
			this.buscarChatScript();
			this.container = $("#chatbot-container-live-preview").get(0).contentWindow;
			this.buscaChatConfiguracao();
		},

		created() {
			window.refreshPreview = () => this.refreshPreviewChatBotConfig();
		}
	}
</script>

<style scoped>
	iframe#chatbot-container-live-preview {
		display: block;
		border: 0;
		width: 400px;
		height: 530px;
	}

	a {
		color: gray;
	}

		a.active {
			color: black;
		}

	.card {
		padding: 0;
	}

	.campo-texto-link {
		width: 100%;
		min-height: 150px;
		background-color: var(--cinza-3);
		resize: none;
		word-break: break-all;
	}

	.container-config {
		width: 85%;
		min-height: 520px;
	}

	.container-config-arquivo {
		width: 100%;
		height: 80px;
		font-size: 14px;
		border: 2px dashed #ccc;
	}

		.container-config-arquivo > .input-file {
			width: 100%;
			margin: 12px !important;
		}

	.container-config-inputs {
		width: 100%;
		font-size: 14px;
	}

		.container-config-inputs > .col {
			width: 100%;
			padding: .2rem 0;
		}

	.container-chat {
		margin: 0 auto;
		width: 400px;
	}

	.chat-imagem {
		height: 50px;
		width: 50px;
		margin: 5px 10px 0 10px;
		border-radius: 50%;
		float: left;
	}
</style>