import { render, staticRenderFns } from "./ChatConfiguracaoPage.vue?vue&type=template&id=235bea68&scoped=true&"
import script from "./ChatConfiguracaoPage.vue?vue&type=script&lang=js&"
export * from "./ChatConfiguracaoPage.vue?vue&type=script&lang=js&"
import style0 from "./ChatConfiguracaoPage.vue?vue&type=style&index=0&id=235bea68&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "235bea68",
  null
  
)

export default component.exports